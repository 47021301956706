<template>
  <div :class="[{'stripe': stripe, 'hoverFlat': hoverFlat}, `vs-table-${color}`]">
    <!-- header -->
    <div class="vs-component vs-con-table">
      <header :class="$store.state.AppActiveUser.userRole === 'merchant' ? 'xl:w-2/5' : 'xl:w-1/2'" class="header-table vs-table--header relative lg:w-3/4 w-full ml-auto">
        <slot name="header"></slot>
        <div v-if="search" class="con-input-search vs-table--search mt-4">
          <input v-if="$route.name === 'merchant-integrations'" :placeholder="$t('Search by tracking number')" id="searchInput" v-model="searchx" class="input-search vs-table--search-input" type="text">
          <input v-else :placeholder="orders ? $route.name.includes('supplies') ?  $t('Merchant Name') : ($route.name.includes('fulfillment') ? $t('Customer name, ') : $t('Tracking number, ')) + $t('or reference number...') : $t('Search')" id="searchInput" v-model="searchx" class="input-search vs-table--search-input" type="text">
          <vs-icon icon="search" id="searchIcon"></vs-icon>
          <template v-if="searchDate">
            <img v-if="!startDate || filterDateModal" @click="OpenFilterDateModal" id="calenderIcon" class="absolute right-0 mt-1 cursor-pointer" src="@/assets/images/pages/calender.svg" alt="">
            <div v-else class="absolute right-0 flex items-center">
              <p class="text-primary">
                {{common.gettingDate(new Date(startDate).toISOString()).split(' ')[1] + ' ' + common.gettingDate(new Date(startDate).toISOString()).split(' ')[2]}} - 
                {{endDate ? common.gettingDate(new Date(endDate).toISOString()).split(' ')[1] + ' ' + common.gettingDate(new Date(endDate).toISOString()).split(' ')[2] : ''}}
              </p>
              <img @click="closeFilterDateModal('close')" id="calenderIcon" class="cursor-pointer" src="@/assets/images/pages/Cancel-date.svg" alt="">
            </div>
          </template>
        </div>
        <!-- ======= FILTER DATE MODAL START ======= -->
        <div v-if="filterDateModal" :class="filterDateModal ? 'visible' : 'hidden'" class="calender rounded-md pt-6 p-4">
          <p class="text-center text-primary text-lg font-medium mb-5">{{ $t('Select orders criteria you want to search!') }}</p>
          <div class="clearfix flex justify-center">
            <div v-for="(criteria, index) in criterias" :key="index">
              <label @click="checkCriteria(criteria)" class="prompt cursor-pointer whitespace-nowrap">
                <input v-model="criteriaName" :class="criteria.checked ? 'checked' : ''" :value="criteria.value" type="radio" name="select" />
                <span class="text-center py-2 px-4 rounded mx-1">{{ criteria.name }}</span>
              </label>
            </div>
          </div>
          <p class="text-center text-primary text-lg font-medium my-5">{{ $t('Select your search range!') }}</p>
          <div class="grid grid-cols-2 gap-4">
            <div class="md:col-span-1 col-span-2 flex justify-center">
              <datepicker :inline="true" name="from" :disabledDates="disabledDatesStart" v-model="startDate"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
            </div>
            <div class="md:col-span-1 col-span-2 flex justify-center">
              <datepicker :inline="true" name="to" :disabledDates="disabledDates" v-model="endDate"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
            </div>
          </div>
          <div class="flex justify-evenly mt-3">
            <p class="text-blue-900 date-border p-2">{{startDate ? common.gettingDate(new Date(startDate).toISOString()) : $t('No Date')}}</p>
            <div class="flex" :style="$i18n.locale === 'ar' ? 'transform: rotate(180deg);' : ''">
              <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
              <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
            </div>
            <p class="text-blue-900 date-border p-2">{{endDate ? common.gettingDate(new Date(endDate).toISOString()) : $t('No Date')}}</p>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <button @click="closeFilterDateModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
            <button @click="searchByDate" :class="startDate && criteriaName ? 'active-btn' : 'disable-btn pointer-events-none'" class="btn">{{ $t('Search') }}</button>
          </div>
        </div>
        <div :class="filterDateModal ? 'show-modal' : ''"></div>
        <!-- ======= FILTER DATE MODAL END ======= -->
      </header>
    </div>
    <div class="con-tablex vs-table--content">
      <div
        :style="styleConTbody"
        class="vs-con-tbody vs-table--tbody">

        <!-- TableLoader -->
        <table v-if="tableLoader" class="table-loader w-full mt-3 text-center">
          <tr>
            <th v-for="(h, index) in 10" :key="index" class="py-3 lg:px-7 px-3">  
              <div class="line w-full rounded-lg h-4 my-3"></div>
            </th>
          </tr>
          <tr v-for="(r, index) in 5" :key="index">
            <td v-for="(b, index) in 10" :key="index" class="py-3 lg:px-5 px-1">
              <div class="line w-full rounded-lg h-4 my-2"></div>
            </td>
          </tr>
        </table>

        <table v-else
          ref="table"
          class="vs-table vs-table--tbody-table text-center shipblu-table">
          <thead
            ref="thead"
            class="vs-table--thead">
            <tr class="header">
              <th
                v-if="multiple || hasExpadableData"
                :class="orders ? '' : 'border-none'">
                <span
                  v-if="multiple"
                  class="header w-16">
                  <vs-checkbox
                    class="flex justify-center"
                    :key="isCheckedLine ? 'remove' : 'check'"
                    :icon="isCheckedLine ? 'remove' : 'check'"
                    :checked="isCheckedMultiple"
                    size="small"
                    @change="changeCheckedMultiple"/>
                </span>
              </th>
              <slot name="thead"></slot>
            </tr>
          </thead>
          <slot :data="datax"></slot>
        </table>
      </div>
      <div
        v-if="isNoData && !tableLoader"
        class="not-data-table vs-table--not-data">
        {{ noDataText }}
      </div>

      <div
        v-if="pagination"
        class="con-pagination-table vs-table--pagination">
        <vs-pagination
          v-model="currentx"
          :total="searchx && !sst ? getTotalPagesSearch : getTotalPages"
          :description-items="descriptionItems"
          :max-items="maxItemsx"
          :size-array="queriedResults.length"
          :description="description"
          :description-title="descriptionTitle"
          :description-connector="descriptionConnector"
          :description-body="descriptionBody"
          @changeMaxItems="changeMaxItems"
        >
        </vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import common from '../../assets/utils/common'

/* eslint-disable */
export default {
  name: "VsTable",
  props:{
    tableLoader: {
      type: Boolean,
      default: false
    },
    orders: {
      type: Boolean,
      default: false
    },
    value:{},
    color: {
      default:'primary',
      type: String
    },
    noDataText: {
      default: 'No data Available',
      type: String
    },
    stripe:{
      default: false,
      type: Boolean
    },
    hoverFlat:{
      default: false,
      type: Boolean
    },
    maxHeight:{
      default: 'auto',
      type: String
    },
    multiple:{
      default: false,
      type: Boolean
    },
    data:{
      default: null,
    },
    notSpacer:{
      default:false,
      type:Boolean
    },
    search:{
      default: false,
      type: Boolean
    },
    searchDate:{
      default: false,
      type: Boolean
    },
    maxItems:{
      default: 5,
      type: [Number, String]
    },
    pagination:{
      default: false,
      type: Boolean
    },
    description:{
      default: false,
      type: Boolean
    },
    descriptionItems:{
      default: () => [],
      type: Array
    },
    descriptionTitle: {
      type:String,
    },
    descriptionConnector: {
      type:String,
    },
    descriptionBody: {
      type:String,
    },
    currentPage: {
      default: 1,
      type: Number | String
    },
    sst:{
      default: false,
      type: Boolean
    },
    total: {
      type: Number,
      default: 0
    },
    onlyClickCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data:()=>({
    common,
    criterias: [
      {
        name: 'Created',
        value: 'created',
        checked: false
      },
      {
        name: 'Picked up',
        value: 'pickup_date',
        checked: false
      }
    ],
    criteriaName: '',
    filterDateModal: false,
    disabledDates: {
      from: new Date(),
    },
    disabledDatesStart: {
      from: new Date() // Disable all dates up tscrollbarTago specific date
    },
    startDate: '',
    endDate: '',
    headerWidth: '100%',
    trs: [],
    datax: [],
    searchx: null,
    currentx: 1,
    maxItemsx: 5,
    hasExpadableData: false,
    currentSortKey: null,
    currentSortType: null
  }),
  computed:{
    getTotalPages() {
      const totalLength = this.sst && this.total ? this.total : this.data.length
      return Math.ceil(totalLength / this.maxItemsx)
    },
    getTotalPagesSearch() {
      return Math.ceil(this.queriedResults.length / this.maxItems)
    },
    queriedResults() {
      let queriedResults = this.data
      if(this.searchx && this.search) {
        let dataBase = this.data
        queriedResults = dataBase.filter((tr)=>{
          let values = this.getValues(tr).toString().toLowerCase()
          return values.indexOf(this.searchx.toLowerCase()) != -1
        })
      }
      return queriedResults
    },
    isNoData() {
      if(typeof(this.datax) == Object) {
        return this.datax ? Object.keys(this.datax).length == 0:false && this.search
      } else {
        return this.datax?this.datax.length == 0:false && this.search
      }
    },
    isCheckedLine () {
      let lengthx = (this.data !== null && typeof (this.data) === 'object') ? this.data.length : 0
      let lengthSelected = this.value.length
      return lengthx !== lengthSelected
    },
    isCheckedMultiple () {
      return this.value.length > 0
    },
    styleConTbody () {
      return {
        maxHeight: this.maxHeight,
        overflow: this.maxHeight != 'auto'?'auto':null
      }
    },
    getThs () {
      let ths = this.$slots.thead.filter(item => item.tag )
      return ths.length
    }
  },
  watch:{
    '$route.params.warehouseID' (val) {
      this.searchx = null
    },
    'filterDateModal' () {
      this.disabledDatesStart = {
        from: new Date() // Disable all dates up tscrollbarTago specific date
      }
    },
    'startDate' () {
      const date = new Date(this.startDate)
      this.endDate = this.startDate ? new Date(this.startDate) : ''
      this.disabledDates.to = date
    },
    currentPage() {
      this.currentx = this.currentPage
    },
    currentx() {
      if(this.sst) {
        this.$emit('change-page', this.currentx)
      } else {
        this.loadData()
      }
    },
    maxItems(val) {
      this.maxItemsx = val
      this.loadData()
    },
    maxItemsx() {
      this.loadData()
    },
    data() {
      this.loadData()
      this.$nextTick(() => {
        if(this.datax.length > 0) {
          this.changeTdsWidth()
        }
      })
    },
    searchx() {
      if(this.sst) {
        this.$emit('search', this.searchx)
      } else {
        this.loadData()
        this.currentx = 1
      }
    }
  },
  mounted () {
    this.maxItemsx = this.maxItems
    this.loadData()
  },
  destroyed () {
  },
  methods:{
    checkCriteria (criteria) {
      this.criterias.forEach(item => {
        if (item.name === criteria.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    OpenFilterDateModal () {
      document.getElementById('searchInput').style.zIndex = '999999';
      document.getElementById('searchIcon').style.zIndex = '999999';
      document.getElementById('calenderIcon').style.zIndex = '999999';
      this.startDate = ''
      this.endDate = ''
      this.filterDateModal = true
    },
    closeFilterDateModal (type) {
      this.filterDateModal = false
      this.startDate = ''
      this.endDate = ''
      this.criteriaName = ''
      this.criterias.map(item => item.checked = false)
      type === 'close' ? this.$emit('searchDate', this.startDate, this.endDate, this.criteriaName) : ''
      document.getElementById('searchInput').style.zIndex = 'auto';
      document.getElementById('searchIcon').style.zIndex = 'auto';
      document.getElementById('calenderIcon').style.zIndex = 'auto';
    },
    searchByDate () {
      this.$emit('searchDate', this.startDate, this.endDate, this.criteriaName)
      this.filterDateModal = false
      document.getElementById('searchInput').style.zIndex = 'auto';
      document.getElementById('searchIcon').style.zIndex = 'auto';
      document.getElementById('calenderIcon').style.zIndex = 'auto';
    },
    loadData() {
      let max = Math.ceil(this.currentx * this.maxItemsx)
      let min = max - this.maxItemsx
      if(!this.searchx || this.sst) {
        this.datax = this.pagination ? this.getItems(min, max) : this.sortItems(this.data) || [];
      } else {
        this.datax = this.pagination ? this.getItemsSearch(min, max) : this.getItemsSearch(min, max) || []
      }
    },
    getItems(min, max) {
      let dataBase = this.sortItems(this.data);
      let items = []
      dataBase.forEach((item, index) => {
        if(index >= min && index < max) {
          items.push(item)
        }
      })
      return items
    },
    sortItems(data) {
      const { currentSortKey, currentSortType } = this;
      function compare(a,b) {
        if (a[currentSortKey] < b[currentSortKey])
          return currentSortType == 'desc'?1:-1;
        if (a[currentSortKey] > b[currentSortKey])
          return currentSortType == 'desc'?-1:1;
        return 0;
      }
      return currentSortType !== null ? [...data].sort(compare) : [...data];
    },
    getItemsSearch(min, max) {
      const search = this.normalize(this.searchx);
      return this.sortItems(this.data).filter((tr)=>{
        return this.normalize(this.getValues(tr).toString()).indexOf(search) != -1
      }).filter((_, index) => {
        return (index >= min && index < max);
      });
    },
    sort(key, sortType) {
      this.currentSortKey = key;
      this.currentSortType = sortType;
      if(this.sst) {
        this.$emit('sort', key, sortType)
        return
      }
      this.loadData();
    },
    normalize(string) {
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    },
    getValues: function getValues(obj) {
      function flattenDeep(val) {
        return Object.values(val || []).reduce((acc, val) => (typeof val === 'object') ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
      }
      return flattenDeep(obj).filter(function (item) {
        return (typeof item === 'string') || (typeof item === 'number');
      });
    },
    changeCheckedMultiple () {
      let lengthx = this.data.length
      let lengthSelected = this.value.length
      let selectedx = (lengthx - lengthSelected)
      if (selectedx == 0) {
        this.$emit('input', [])
      } else {
        this.$emit('input', this.data)
      }
    },
    handleCheckbox(tr) {
      if(this.multiple && this.onlyClickCheckbox){
        let val = this.value.slice(0)
        if(val.includes(tr)) {
          val.splice(val.indexOf(tr),1)
        } else {
          val.push(tr)
        }
        this.$emit('input', val)
        this.$emit('selected', tr)
      }
    },
    clicktr (tr, isTr) {
      if(this.multiple && isTr && !this.onlyClickCheckbox){
        let val = this.value.slice(0)
        if(val.includes(tr)) {
          val.splice(val.indexOf(tr),1)
        } else {
          val.push(tr)
        }
        this.$emit('input', val)
        this.$emit('selected', tr)
      } else if (isTr && !this.onlyClickCheckbox) {
        this.$emit('input', tr)
        this.$emit('selected', tr)
      }
    },
    dblclicktr (tr, isTr) {
      if (isTr) {
        this.$emit('dblSelection',tr)
      }
    },
    changeTdsWidth() {
      if(!this.value) return
      let tbody = this.$refs.table ? this.$refs.table.querySelector('tbody') : ''
      // Adding condition removes querySelector none error - if tbody isnot present
      if(tbody) {
        let trvs = tbody.querySelector('.tr-values')
        if (trvs === undefined || trvs === null ) return
        let tds = trvs.querySelectorAll('.td')
        let tdsx = []
        tds.forEach((td, index) => {
          tdsx.push({index: index, widthx: td.offsetWidth})
        });
        let colgrouptable = this.$refs.colgrouptable
        if (colgrouptable !== undefined && colgrouptable !== null ) {
          let colsTable = colgrouptable.querySelectorAll('.col')
          colsTable.forEach((col, index) => {
            col.setAttribute('width', tdsx[index].widthx)
          })
        }
      }
    },
    changeMaxItems (index) {
      this.maxItemsx = this.descriptionItems[index]
    }
  },
  components: {
    Datepicker
  },
  created () {
    this.searchx = this.$route.query.search ? this.$route.query.search : null
    if (this.$route.name.includes('returns-view') || this.$route.name.includes('head-of-fleet-returns') || this.$route.query.tab === 'Returns') {
      this.criterias.push({
        name: 'Returned',
        value: 'returned_date',
        checked: false
      })
    } else if (this.$route.path.includes('orders/delivery') || this.$route.name.includes('merchant-view') || this.$route.name.includes('head-of-fleet-orders') || this.$route.query.tab === 'Delivery Orders') {
      this.criterias.push({
        name: 'Delivered',
        value: 'delivered_date',
        checked: false
      })
    }
  }
}
</script>

<style lang="scss">
label.prompt input {
  display: none;
}
label.prompt span {
  background: #F1F3F6;
  border-radius: 4px;
  border: 0.6px solid #F1F3F6;
}
[dir=ltr] label.prompt span {
  float: left;
}
[dir=rtl] label.prompt span {
  float: right;
}
.checked + span{
  background: #FFFFFF !important;
  border: 0.6px solid #1C5BFE !important;
  color: #1C5BFE !important;
  font-weight: 500 !important;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28) !important;
  border-radius: 4px !important;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
input[type=checkbox] + label.prompt{
  color: #6C84A3;
}
input[type=checkbox]:checked + label{
  background: #FFFFFF;
  border: 0.6px solid #1C5BFE;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
}
.calender {
  width: 642px;
  position: absolute;
  top: 65px;
  right: 0;
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.28);
}
@media (max-width: 767px) {
  .calender {
    width: unset;
    max-width: 370px;
  }
}
.show-modal {
  visibility: visible;
  opacity: 1 !important;
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(10, 50, 102, 0.24);
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: all 0.3s ease;
}
.date-border {
  border-bottom: 0.6px solid #9DADC2;
}
.btn {
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 18px;
} 
.disable-btn {
  background: #F1F3F6;
  color: #6C84A3;
}
.active-btn {
  color: #fff;
  font-weight: 500;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
.scroll {
  overflow-x: auto;
  visibility: visible;
} 
table.shipblu-table {
  width: 100%;
  border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 0px 8px;
  .header {
    background: rgba(220, 238, 255, 0.4);
    border-radius: 6px;
  }
  .data {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(157, 173, 194, 0.4);
  }
  th {
    &:first-child {
      border-left: 16px solid #edf4fb;
    }
    position: relative;
    padding: 15px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A3266;
    text-align: center !important;
    &::after {
      content: "|";
      position: absolute;
      right: 0%;
      top: calc(50% - 8.5px);
    }
    &:last-child::after {
      content: "";
    }
    input {
      position: absolute;
      top: calc(50% - 6.5px);
    }
  }
  td {
    vertical-align: middle;
    .hidden:first-child {
      border: none !important;
    }
    &:first-child {
      padding: 0;
      border-left: 16px solid;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    padding: 8px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0A3266;
  }
  .status-icon {
    border-left: 4px solid;
    border-radius: 8px;
  }
}
.link {
  cursor: pointer;
  text-decoration: underline;
  color: #1c5bfe !important
}

// TableLoader
@mixin background-gradient {
  background-image: linear-gradient(45deg, #0A326630 0px, rgba(229, 229, 229, 0.8) 40px, #0A326630 80px);
}
.table-loader {
  th{
    background: rgba(220, 238, 255, 0.4);
    border-radius: 6px;
  }
  .line{
    @include background-gradient;
    animation: shine-lines 1s infinite alternate;
  }
}
@keyframes shine-lines {
  0% {background-position: 200px;}
  100% {background-position: -200px;}
}
</style>
